<template>
  <div>
    <div class="modidy_Auth">
      <div class="main">
        <div class="step">
          <el-steps align-center :active="active">
            <el-step v-if="$route.query.type == 1" v-for="(item,index) in $t(`personalCenter.account.stepsPhoneTitle`)"
              :title="item" :key="index"></el-step>
            <el-step v-if="$route.query.type == 2" v-for="(item,index) in $t(`personalCenter.account.stepsWechatTitle`)"
              :title="item" :key="index"></el-step>
          </el-steps>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="form" @submit="submitForm('ruleForm')"
          v-if="(active != 3 && $route.query.type == 1 ) || ($route.query.type == 2 && active == 1) || ($route.query.type == 3 && active == 1)">
          <el-form-item prop="phone">
            <el-input v-model="ruleForm.phone" v-if="$route.query.type != 2"  auto-complete="off" :placeholder="$t(`logins.form.phone.placeholder`)"
             @keyup.enter.native="submitForm('ruleForm')">
              <template slot="prepend">

                <el-dropdown @command="handleCommand">
                    <span style="display: flex;align-items: center;margin-top: 6px" class="el-dropdown-link">
                      <img style="height: 27px" :src="regionCodeObject.srcImg" alt=""> <span style="font-size: 16px;margin-left: 5px"> +{{regionCodeObject.code}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item  command="86">86(中国大陆)</el-dropdown-item>
                    <el-dropdown-item  command="852">852(中国香港)</el-dropdown-item>
                    <el-dropdown-item  command="853">853(中国澳门)</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-input>
            <span v-else class="el-input__inner">{{$store.state.me.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}}</span>
          </el-form-item>
          <el-form-item prop="code" class="code">
            <el-input v-model="ruleForm.code" auto-complete="off" :placeholder="$t(`logins.form.code.placeholder`)"
              maxlength="6" @keyup.enter.native="submitForm('ruleForm')"></el-input>
            <el-button class="code_btn" :disabled="codeDisabled" @click="getCode">{{codeMsg}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" @click="submitForm('ruleForm')" :loading="loading>0" :disabled="disabledBtn">
              {{active == 1 ? $t(`personalCenter.account.submitValida`) : active == 2 ? $t(`personalCenter.account.submit`) : $t(`personalCenter.account.backAccount`)}}
            </el-button>
          </el-form-item>
        </el-form>
        <div class="auth_code" v-show="this.$route.query.type == 2 && this.active == 2">
          <dl>
            <dt id="qrcode">
            </dt>
            <dd>
              <p>{{$t('personalCenter.account.wechatTip1')}}</p>
              <p>{{$t('personalCenter.account.wechatTip2')}}</p>
            </dd>
          </dl>
        </div>
        <div class="success" v-if="active == 3">
          <p>
            <i><img src="@/assets/images/common/success_icon.png" alt=""></i>
            {{ $route.query.type == 1 ? $t('personalCenter.account.successRevision') : $t('personalCenter.account.successRelease')}}
          </p>
          <div class="btn" @click="backAccount">{{$t('personalCenter.account.backAccount')}}</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
      :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script>
  import {mapMutations, mapState} from 'vuex'
  import { USER } from "@/request/common";
import login from "@/components/common/login"
import footers from '@/components/common/xfooter'
export default {
  name: "modidyOrAuth",
  //   head () {
  //     return this.$setSeo(this.$t("login.h1") + " | Lanternfish", "login")
  //   },
  data () {
    // 表单验证规则
    // 校验手机号码
    // 表单验证规则
    // 校验手机号码
    var phoneReg = /^[1][0-9]{10}$/,
      codeReg = /^\d{6}$/;
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('logins.form.phone.placeholder')))
      }
      setTimeout(() => {
        if (!value) {
          callback(new Error(this.$t('logins.form.phone.errorMessage')))
        } else {
          callback()
        }
      }, 1000)
    };
    // 校验验证码
    var validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('logins.form.code.placeholder')))
      }
      setTimeout(() => {
        if (!codeReg.test(value)) {
          callback(new Error(this.$t('logins.form.code.errorMessage')))
        } else {
          callback()
        }
      }, 1000)
    };
    return {
      regionCodeObject: {
        srcImg: '/images/national/zgdl.jpg',
        code: '86'
      },
      selectVal: "86",
      loading: 0,
      routerType: 0,
      active: 1,  //1 第一步 2 第二步
      // 是否禁用按钮
      codeDisabled: false,
      // 倒计时秒数
      countdown: 60,
      // 按钮上的文字
      codeMsg: this.$t('logins.form.sendCodeText'),
      // 定时器
      timer: null,
      // 表单参数
      ruleForm: {
        phone: "",
        code: ""
      },
      token: Math.random(),
      // 表单验证
      rules: {
        phone: [
          { required: true, validator: validatePhone, trigger: 'blur' }
        ],
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: "blur"
          }
        ]
      },
      disabledBtn: false,
      dialogVisible: false,
      dialogClose: false,
    }
  },
  components: {
    login,footers,
    ...mapState(['me'])
  },
  mounted () {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    document.body.appendChild(s);
    this.routerType = this.$route.query.type
    if (this.$route.query.active != null) {
      this.active = this.$route.query.active;
    } else {
      setTimeout(() => {
        this.wxLogins()
      }, 500);
    }
    // 获取用户信息
    if(this.$route.query.type == 2){
      this.getUserInfo();
    }
  },
  methods: {
    ...mapMutations(['setMe', 'setToken']),
    handleCommand(command) {
      if(command == '86'){
        this.selectVal = command
        this.regionCodeObject.srcImg = '/images/national/zgdl.jpg'
        this.regionCodeObject.code = command
      }else if(command == '852'){
        this.selectVal = command
        this.regionCodeObject.srcImg = '/images/national/zgxg.jpg'
        this.regionCodeObject.code = command
      }else if(command == '853'){
        this.selectVal = command
        this.regionCodeObject.srcImg = '/images/national/am.jpg'
        this.regionCodeObject.code = command
      }
    },
    // 获取用户信息
    async getUserInfo () {
      let user = await USER();
      if (user.code == 0) {
        this.setMe(user.data);
        this.ruleForm.phone = this.$store.state.me.phone
      }
    },
    wxLogins () {
      var appid = "";
      var uriAll = window.location.href;
      var getindex = uriAll.indexOf(".cn");//获取指定字符串在原字符串条中的位置
      var getstr = uriAll.substring(0, getindex);
      if (getstr.indexOf("test.lanternfish") != -1) {
        appid = 'wx855ff947adfb6ef2'
      } else {
        appid = 'wx6a278bb6f3d73649'
      }
      this.tool.setCookie("loginUrl", this.$route.path);
      var obj = new WxLogin({
        self_redirect: false,
        id: 'qrcode',
        appid: appid,
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(`http://${window.location.host}/callback`),
        state: '/',
        style: 'black',
        href: 'data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsNCi5pbXBvd2VyQm94e3dpZHRoOmF1dG87fQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIyN3B4O2hlaWdodDogMjI3cHg7bWFyZ2luLXRvcDowO2JvcmRlcjpub25lO30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAud3JwX2NvZGV7d2lkdGg6MjI3cHg7fQ==',
      });
    },
    // 获取验证码
    getCode () {
      this.codeDisabled = true;
      setTimeout(() => {
        this.codeDisabled = false;
      }, 1000);
      this.$refs.ruleForm.validateField('phone', async phoneError => {
        if (!phoneError) {
          let data = await this.$axios.post(
            '/admin/user/checkUser', {
            username: this.selectVal == '86'  ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone,
            authType: "sms",
          }
          )
          if (data.data.code == 1) {
            if (this.active == 1) {
              if (data.data.data.type == '1') {
                // 验证码60秒倒计时
                if (!this.timer) {
                  this.timerEvent();
                }
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            } else if (this.active == 2 && this.routerType == 1) {
              if (data.data.data.type == '3') {
                // 验证码60秒倒计时
                if (!this.timer) {
                  this.timerEvent();
                }
              } else if (data.data.data.type == '1' || data.data.data.type == '2') {
                this.$message({
                  message: "该用户已存在，请直接登录"
                });
              }
            }
          }

        }
      });
    },
    // 倒计时
    timerEvent () {
      this.codeDisabled = true;
      this.timer = setInterval(() => {
        if (this.countdown > 0 && this.countdown <= 60) {
          this.countdown--;
          if (this.countdown !== 0) {
            this.codeMsg = this.countdown + " s";
          } else {
            clearInterval(this.timer);
            this.codeMsg = this.$t('logins.form.resendText');
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
          }
        }
      }, 1000)
      this.smsAjax();
    },
    // 验证码接口
    async smsAjax () {
      const codeData = await this.$axios.get(
        '/common/code/sms', {
        params: {
          subject: this.selectVal == '86'  ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone,
          token: this.token,
          locale: 'lantern'
        }
      }
      )
      if (codeData.data.msg == 'success') {
        // this.$message({
        //   type: "success",
        //   message: codeData.data.msg
        // });
      } else {
        this.$message({
          message: codeData.data.data
        });
        clearInterval(this.timer);
        this.codeMsg = this.$t('logins.form.resendText');
        this.countdown = 60;
        this.timer = null;
        this.codeDisabled = false;
      }
    },
    // 表单提交
    submitForm (formName) {
      this.disabledBtn = true;
      setTimeout(() => {
        this.disabledBtn = false;
      }, 1000);
      this.$refs[formName].validate(async result => {
        if (result) {
          this.disabledBtn = true;
          this.loading++;
          this.unClick = true;
          let loginData;
          let authData;
          // 表单登录接口
          if (this.active == 1) {
            /*loginData = await this.$axios.post(
              '/admin/user/verification', {
                username: this.ruleForm.phone,
                code: this.ruleForm.code,
                token: this.token,
                authType: "sms",
              }
            )*/
            loginData = await this.$axios({
              url: '/admin/user/verification',
              method: 'post',
              params: {
                code: this.ruleForm.code,
                token: this.token,
                authType: "sms",
              },
              data: {
                username: this.selectVal == '86'  ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone,
                authType: "sms",
              }
            })
          } else if (this.active == 2 && this.routerType == 1) {
            this.tool.setCookie("phone", this.selectVal == '86'  ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone);
            loginData = await this.$axios.post(
              '/admin/user/editInfo', {
              username: this.selectVal == '86'  ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone,
              code: this.ruleForm.code,
              token: this.token,
              authType: "sms",
            }
            )
          }
          if (loginData.data.code == 0) {
            this.$refs[formName].resetFields();
            clearInterval(this.timer);
            this.codeMsg = this.$t('logins.form.sendCodeText');
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
            if (this.active == 1) {
              this.active = 2
            } else if (this.active == 2 && this.routerType == 1) {
              this.reload();
              this.active = 3
            } else if (this.active == 2 && this.routerType == 2) {
              this.tool.setCookie("loginUrl", this.$route.path);
              this.wxLogins();
            }
          } else if (loginData.data.code == 1) {
            clearInterval(this.timer);
            this.codeMsg = this.$t('logins.form.resendText');
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
            if (loginData.data.data.verificationCode == 'false') {
              this.$message({
                message: loginData.data.data.msg
              });
            }
            if (loginData.data.data.isCurrentUser == 'false') {
              this.$message({
                message: loginData.data.data.msg
              });
            }
            if (this.active == 1) {
              if (loginData.data.data.isPresence == 'false') {
                this.$message({
                  message: loginData.data.data.msg
                });
              }
            }
            if (this.active == 2 && this.routerType == 1) {
              if (loginData.data.data.isPresence == 'true') {
                this.$message({
                  message: loginData.data.data.msg
                });
              }
            }
          }
          this.disabledBtn = false;
          this.loading--;
        }
      });
    },
    reload () {
      this.$axios.get('/auth/oauth/removeToken');
      let updatePhone = this.tool.getCookie("phone");
      this.tool.clearCookie("auth");
      this.$axios.post(
        '/auth/user/updateAutoLogin', {
        phone: updatePhone
      }
      ).then((data) => {
        if (data.data.code == 0) {
          if (data.data.data.access_token) {
            this.setToken(data.data.data.access_token)
          }
        }
      })
    },
    closeEvent (done) {
      this.dialogClose = true;
      done();
    },
    // 登录弹层隐藏
    loginDialog (data) {
      this.dialogVisible = data;
      if (this.tool.getCookie("auth") != '') {
        this.$router.push({
          path: "/personalCenter/account"
        })
      }
    },
    dialogCloseBool (data) {
      this.dialogClose = data;
    },
    backAccount () {
      if (!this.tool.getCookie("auth")) {
        this.dialogVisible = true;
      } else {
        this.$router.push({
          path: "/personalCenter/account"
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.modidy_Auth {
  background: url("@{imgUrl}common/bg.png") no-repeat center bottom;
  background-size: 100%;
  padding: 144px 0 60px;
  box-sizing: border-box;
  min-height: calc(100vh - 84px);
  .main {
    width: 450px;
    height: 540px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(110, 119, 123, 0.16);
    border-radius: 5px;
    margin: 0 auto;
    .step {
      border-radius: 5px 5px 0 0;
      height: 124px;
      background: @blue;
      padding: 40px 40px 30px;
      box-sizing: border-box;
    }
    .form {
      padding: 60px 55px 0;
      /deep/.el-input-group__prepend {
        /*width: 75px;*/
        /*padding-left: 24px;*/
        border-right: 1px solid #DCDFE6;
        padding: 0 5px;
      }
      .code {
        position: relative;
        .code_btn {
          position: absolute;
          top: 0;
          right: 18px;
          background: #fff;
          color: #888;
          border: none;
          padding: 12px 0;
          &:hover {
            color: @blue;
          }
          &.is-disabled {
            color: #dce8fb;
          }
        }
      }
      .btn {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        background: @blue;
        color: #fff;
        border: none;
        margin: 28px 0 0;
        font-size: 16px;
        &:hover {
          box-shadow: @boxShadow;
        }
      }
    }
    .success {
      padding: 0 55px;
      p {
        text-align: center;
        font-size: 20px;
        color: #595a5e;
        line-height: 254px;
        i {
          display: inline-flex;
          width: 36px;
          height: 36px;
          margin-right: 7px;
          vertical-align: middle;
          img {
            width: 100%;
          }
        }
      }
      .btn {
        display: block;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 5px;
        background: @blue;
        color: #fff;
        margin: 0 auto;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          box-shadow: @boxShadow;
        }
      }
    }
    .auth_code {
      dl {
        dt {
          width: 230px;
          height: 230px;
          margin: 50px auto 30px;
          border: 1px solid #c2c2c2;
          box-sizing: border-box;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        dd {
          p {
            font-size: 14px;
            color: #c2c2c2;
            text-align: center;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.modidy_Auth {
  .el-input__inner {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #dbdcdd;
    font-size: 14px;
    color: #888;
  }
  .el-form-item__error {
    padding-left: 15px;
  }
  .el-step__icon-inner {
    display: none;
  }
  .el-step__icon {
    width: 22px;
    height: 22px;
    background-color: @blue;
    border: solid 3px #2a87fc;
  }
  .el-step__head.is-finish {
    border-color: #fff;
    .el-step__icon.is-text {
      background-color: @blue;
      border: solid 3px #ffffff;
    }
  }
  .el-step__title.is-finish,
  .el-step__title.is-process,
  .el-step__title.is-wait {
    font-size: 14px;
    color: #fff;
    font-weight: normal;
  }
  .el-step__line {
    background-color: #2a87fc;
  }
  #qrcode {
    img {
      width: 100%;
    }
  }
}
</style>
